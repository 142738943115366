import { Ref, computed } from '@vue/composition-api';
import * as R from 'ramda';
import { format } from 'sql-formatter';
import { identify } from 'sql-query-identifier';
import { IdentifyResult } from 'sql-query-identifier/lib/defines';
import { SQLRetrievalMethod } from '../constants';
import { ApolloTask, BigQueryHarvesterConfiguration, SQLHarvesterConfiguration } from '../types';

export function useSQLQuery(task: Ref<ApolloTask<BigQueryHarvesterConfiguration | SQLHarvesterConfiguration>>) {
    const retrievalDetails: Ref<{
        method: SQLRetrievalMethod;
        query: string | null;
        table: string | null;
        freshDataColumn?: string | null;
        identifierColumn?: string | null;
    }> = computed(() =>
        R.pick(['method', 'query', 'table', 'freshDataColumn', 'identifierColumn'], task.value.configuration),
    );

    const sqlType = computed((): {
        dialect: 'generic' | 'bigquery' | 'mysql' | 'psql' | 'mssql';
        language: 'sql' | 'bigquery' | 'mysql' | 'postgresql' | 'tsql' | 'mariadb';
    } => {
        if ((task.value.configuration as SQLHarvesterConfiguration).sqlType) {
            switch ((task.value.configuration as SQLHarvesterConfiguration).sqlType) {
                case 'mssql':
                    return { dialect: 'mssql', language: 'tsql' };
                case 'postgresql':
                    return { dialect: 'psql', language: 'postgresql' };
                case 'mysql':
                    return { dialect: 'mysql', language: 'mysql' };
                case 'mariadb':
                    return { dialect: 'mysql', language: 'mariadb' };
                default:
                    return { dialect: 'generic', language: 'sql' };
            }
        }
        return { dialect: 'bigquery', language: 'bigquery' };
    });

    const isQuery = computed(() => retrievalDetails.value.method === SQLRetrievalMethod.Query);

    const isTable = computed(() => retrievalDetails.value.method === SQLRetrievalMethod.Table);

    const queryIncludesForbiddenTerms = computed(
        () =>
            isQuery.value &&
            !R.isNil(retrievalDetails.value.query) &&
            !!retrievalDetails.value.query.match(
                /([^a-zA-Z0-9]|^)(delete|drop|truncate|create|insert|update|alter|merge)([^a-zA-Z0-9]|$)/gi,
            ),
    );

    const parsedQuery = computed(() => {
        if (isQuery.value && !R.isNil(retrievalDetails.value.query))
            try {
                return identify(retrievalDetails.value.query, { dialect: sqlType.value.dialect });
            } catch {
                return null;
            }
        return undefined;
    });

    const queryIncludesModification = computed(() =>
        parsedQuery.value?.some((value: IdentifyResult) => !['LISTING', 'INFOMATION'].includes(value.executionType)),
    );

    const column = computed(
        () => retrievalDetails.value.identifierColumn ?? retrievalDetails.value.freshDataColumn ?? null,
    );

    const quotationMark = computed(() => (['mssql', 'psql'].includes(sqlType.value.dialect) ? '"' : `\``));

    const sqlQuery = computed(() => {
        // sql query from statement
        if (isQuery.value && !R.isNil(retrievalDetails.value.query)) {
            const finalQuery = retrievalDetails.value.query;
            if (R.isNil(column.value)) return finalQuery;
            return `SELECT * FROM (${finalQuery.endsWith(';') ? finalQuery.slice(0, -1) : finalQuery})${
                sqlType.value.dialect === 'bigquery' ? '' : ' as sub_query'
            }`;
        }
        // sql query from table/view
        else if (isTable.value && !R.isNil(retrievalDetails.value.table)) {
            const finalQuery = `SELECT * FROM ${quotationMark.value}${retrievalDetails.value.table}${quotationMark.value}`;
            if (R.isNil(column.value)) return `${finalQuery};`;
            return finalQuery;
        }
        return null;
    });

    const sqlQueryPreview = computed(() => {
        if (!sqlQuery.value) return '';

        let finalQuery = sqlQuery.value;

        if (!R.isNil(column.value)) {
            const col = `${quotationMark.value}${column.value}${quotationMark.value}`;
            finalQuery += ` WHERE ${col} > 'LAST_RECORD' ORDER BY ${col} ASC;`;
        }

        try {
            return format(finalQuery, {
                language: sqlType.value.language,
                indentStyle: 'tabularLeft',
                keywordCase: 'upper',
            });
        } catch {
            return finalQuery;
        }
    });

    return { queryIncludesForbiddenTerms, parsedQuery, queryIncludesModification, sqlQueryPreview };
}
